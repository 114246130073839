import React from 'react'
import Navbar from '../Navbar'
// import Mainpage from '../M'
import './style.css'

function App () {
  return (
    <div className='App'>
      <Navbar />
      {/* <Mainpage /> */}
    </div>
  )
}

export default App
